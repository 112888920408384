import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pl-2" }
const _hoisted_2 = { class: "mx-1 text-sm" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-400"
}
const _hoisted_4 = {
  key: 0,
  class: "\n            absolute\n            top-full\n            w-full\n            bg-white\n            border-2 border-black\n            px-1\n            shadow-xs\n          "
}
const _hoisted_5 = {
  class: "flex flex-col divide-y divide-gray-400",
  style: {"max-height":"200px","overflow-y":"scroll"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("p", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createVNode("div", {
      class: "border-b-2 border-black w-32 text-sm relative",
      tabindex: 0,
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showOptions = false)),
      style: {"height":"24px"}
    }, [
      _createVNode("div", {
        class: "w-full h-full overflow-hidden",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showOptions = true))
      }, [
        (_ctx.value)
          ? (_openBlock(), _createBlock("p", _hoisted_3, _toDisplayString(_ctx.value.label), 1))
          : _createCommentVNode("", true)
      ]),
      (_ctx.value && _ctx.dismissible)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            class: "absolute right-2 top-2 bottom-0 flex items-center -mt-2 cursor-pointer text-gray-500",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.unselect && _ctx.unselect(...args)))
          }, [
            _createVNode(_component_fa_icon, { icon: ['fas', 'times'] })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_Transition, {
        name: "options-animation",
        appear: ""
      }, {
        default: _withCtx(() => [
          (_ctx.showOptions)
            ? (_openBlock(), _createBlock("div", _hoisted_4, [
                _createVNode("div", _hoisted_5, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                    return (_openBlock(), _createBlock("p", {
                      class: "text-xs cursor-pointer py-1 hover:bg-gray-100",
                      key: item.id,
                      onClick: ($event: any) => (_ctx.onSelect(item))
                    }, _toDisplayString(item.label), 9, ["onClick"]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 32)
  ]))
}