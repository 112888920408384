
import { rutFormatter } from '@/utils';
import {defineComponent, ref} from 'vue';
import {rumaImageUrl} from "@/utils/apiUtils";

export default defineComponent({
  methods: {rumaImageUrl},
  props: {
    emptyMessage: {
      type: String,
      default: 'No hay resultados'
    },
    clickable: {
      type: Boolean,
      default: false
    },
    geo: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => [{ label: 'Primero',  key: 'first', format: '', hidden: false}, {label: 'Segundo',  key: 'second', format: ', hidden: false'}, {label: 'Tercero',  key: 'last', format: '', hidden: false}]
    },
    items: {
      type: Array,
      default: () => [
        {
          uid: 'aaa',
          data: {
            first: 'Umo',
            second: 'Secccc',
            last: 'laststt'
          }
        },
        {
          uid: 'bbb',
          data: {
            first: 'Umo22222',
            second: 'Secccc3333',
            last: 'laststt3333'
          }
        }
      ]
    },
    actions: {
      type: Array,
      default: () => [
        { label: 'console', class: 'bg-yellow-600 text-white', cb: (i: number) => console.log(i) }
      ]
    },
    preImageFn: {
      type: Function,
    }
  },
  emits: ['selected', 'file', 'map'],
  setup(props, { emit }) {
    const selectItem = (item: { [k: string]: any }) => {
      console.log('emit');
      emit('selected', item);
    };
    const setMovimientoText = (mov: any) => {
      switch(mov) {
        case 1:
          return 'COMPRA';
        case 2:
          return 'VENTA';
        case 3:
          return 'DESPACHO';
        case 4:
          return 'RECEPCIÓN';
        case 5:
          return 'AJUSTE';
      }
    };
    const formatted = (val: any, format: string) => {
      switch (format) {
        case 'dni':
          return val ? rutFormatter(val) : '';
        case 'movimiento':
          return val ? setMovimientoText(val) : '';
        default:
          return val;
      }
    };
    //
    const isLoading = ref(false);

    const fileRef = ref<any | null>(null);
    const file = ref<any>(null);
    const rowId = ref('');
    const pickFile = (id: string) => {
      props.preImageFn && props.preImageFn();
      isLoading.value = true;
      rowId.value = id;
      fileRef.value?.click();
    };
    const charge = () => {
      const checkOnCancel = () => {
        const fileElem = document.getElementById('fileinput') as HTMLInputElement;
        if (fileElem && fileElem.value.length == 0) isLoading.value = false;
      };
      document.body.onfocus = function () {
        setTimeout(checkOnCancel, 100);
      };
    };
    const onFilePicked = (evt: any) => {
      file.value = evt.target.files[0];
      emit('file', { id: rowId.value, file: file.value });
      isLoading.value = false;
    };
    const showMap = (ruma: any) => {
      if (ruma.geo && ruma.geo.lat && ruma.geo.lon) {
        emit('map', ruma);
      }
    };
    return {
      selectItem,
      formatted,
      charge,
      onFilePicked,
      fileRef,
      pickFile,
      showMap
    };
  }
});
