
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'UITableNav',
  emits: ['count', 'next', 'previous'],
  props: {
    initial: {
      type: Number,
      default: 1
    },
    actual: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 10
    },
    perPage: {
      type: Number,
      default: 10
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const counter = ref(10);
    const setCounter = (count: number) => {
      page.value = 1;
      counter.value = count;
      if (props.loading) return;
      emit('count', counter.value);
    };
    const page = ref(1);
    const initalCount = computed(() => {
      if (page.value === 1) return 1;
      return props.initial + (props.perPage * (page.value -1));
    });
    const endingCount = computed(() => {
      if (page.value === 1) return props.actual;
      return props.actual + (props.perPage * (page.value -1));
    });
    const previousPage = () => {
      if (page.value > 1) {
        page.value--;
        emit('previous', page.value);
      }
    };
    const nextPage = () => {
      if (props.total > endingCount.value) {
        page.value++;
        emit('next', page.value);
      }
    };
    return {
      counter,
      setCounter,
      previousPage,
      nextPage,
      page,
      initalCount,
      endingCount
    };
  }
});
