
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { SelectItem } from '@/models/SelectItem';

export default defineComponent({
  name: 'UISimpleSelect',
  props: {
    label: {
      type: String,
      default: 'label'
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      default: () => [
        { label: 'Item 1', id: 0 },
        { label: 'Item 2', id: 1 }
      ]
    },
    dismissible: {
      type: Boolean,
      default: true
    },
    default: {
      type: String
    },
  },
  emits: ['selected'],
  setup(props, { emit }) {
    
    const value = ref<SelectItem | null>(null);
    const showOptions = ref(false);
    const onSelect = (item: SelectItem) => {
      value.value = item;
      emit('selected', item.id);
      showOptions.value = false;
    };
    const unselect = () => {
      value.value = null;
      emit('selected', null);
    };
    const clearValue = () => {
      value.value = null;
    };
    onMounted(() => {
      if (props.default) {
        const defaultItem = props.items.filter(el => el.id === props.default);
        if (defaultItem.length === 1) {
          value.value = defaultItem[0];
        }
      }
    });
    return {
      value,
      showOptions,
      onSelect,
      unselect,
      clearValue
    };
  }
});
