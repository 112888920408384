import { toDisplayString as _toDisplayString, createVNode as _createVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "mr-2 text-sm text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("p", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _withDirectives(_createVNode("input", {
      type: "text",
      class: "rounded px-1 focus:outline-none",
      style: {"width":"150px","height":"20px"},
      onInput: _cache[1] || (_cache[1] = evt => _ctx.$emit('input', evt.target.value)),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.value = $event)),
      onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.filterFn && _ctx.filterFn(...args)), ["enter"]))
    }, null, 544), [
      [_vModelText, _ctx.value]
    ]),
    _createVNode("div", {
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onfilter && _ctx.onfilter(...args))),
      class: "\n        go-btn\n        px-1\n        ml-1\n        flex\n        items-center\n        rounded\n        cursor-pointer\n        ring-0\n        border-2 border-white\n        text-white\n        hover:bg-black hover:bg-opacity-10\n      "
    }, [
      _createVNode(_component_fa_icon, { icon: ['fa', 'chevron-right'] })
    ])
  ]))
}