
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'UISimpleInput',
  props: {
    label: {
      type: String,
      default: 'label'
    },
    filterFn: {
      type: Function,
      default: () => console.log('filterFn')
    }
  },
  emits: ['input'],
  setup(props) {
    const value = ref('');
    const clearValue = () => {
      value.value = '';
    };
    const onfilter = () => {
      props.filterFn(value.value);
    };
    return {
      value,
      clearValue,
      onfilter
    };
  }
});
